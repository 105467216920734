.btn-form-submit {
    color: $text-reverse;
    background-color: $nero;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
    font-weight: $text-bold;
    // border: 1px solid $text-highlight;
    display: block;
    width: fit-content;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;

    &.landing {
        text-transform: none;
        margin: 0 auto;
    }

    &:hover,
    &:active {
        background-color: $text-reverse;
        border: 1px solid $text-color;
    }
    &:focus,
    &:active {
        transform: scale(0.95);
        border: 1px solid $text-color;
    }
}

@media (min-width: 768px) {
    .btn-form-submit {
        font-size: 26px;
        padding: 1rem 2rem;

        &.landing {
            font-size: 21px;
            padding: 5px 1rem;
        }

        &.event-list {
            font-size: 16px;
        }
        &.disabled {
            font-size: 16px;
        }
    }
}

@media (min-width: 992px) {
    .btn-form-submit {
        &.landing {
            font-size: 14px;
            margin: 0;

            &.central {
                margin: 0 0 0 auto;
            }
        }
    }
}

@media (min-width: 1200px) {
    .btn-form-submit {
        &.landing {
            font-size: 16px;
        }
    }
}

@media (min-width: 1600px) {
    .btn-form-submit {
        &.landing {
            font-size: 18px;
        }
    }
}
